// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons

// scss-docs-start btn-variant-mixin
@mixin button-variant($state, $variant) {
  $background: map-get($variant, "bg");
  $border-color: map-get($variant, "border-color");
  $color: map-get($variant, "color");
  $hover-background: map-get($variant, "hover-bg");
  $hover-border-color: map-get($variant, "hover-border-color");
  $hover-color: map-get($variant, "hover-color");
  $active-background: map-get($variant, "active-bg");
  $active-border-color: map-get($variant, "active-border-color");
  $active-color: map-get($variant, "active-color");
  $disabled-background: map-get($variant, "disabled-bg");
  $disabled-border-color: map-get($variant, "disabled-border-color");
  $disabled-color: map-get($variant, "disabled-color");
  $shadow: map-get($variant, "shadow");

  .btn-#{$state} {
    --#{$variable-prefix}btn-bg: #{$background};
    --#{$variable-prefix}btn-border-color: #{$border-color};
    --#{$variable-prefix}btn-color: #{$color};
    --#{$variable-prefix}btn-hover-bg: #{$hover-background};
    --#{$variable-prefix}btn-hover-border-color: #{$hover-border-color};
    --#{$variable-prefix}btn-hover-color: #{$hover-color};
    --#{$variable-prefix}btn-active-bg: #{$active-background};
    --#{$variable-prefix}btn-active-border-color: #{$active-border-color};
    --#{$variable-prefix}btn-active-color: #{$active-color};
    --#{$variable-prefix}btn-disabled-bg: #{$disabled-background};
    --#{$variable-prefix}btn-disabled-border-color: #{$disabled-border-color};
    --#{$variable-prefix}btn-disabled-color: #{$disabled-color};
    --#{$variable-prefix}btn-shadow: #{$shadow};
  }
}
// scss-docs-end btn-variant-mixin

// scss-docs-start btn-outline-variant-mixin
@mixin button-outline-variant($state, $variant) {
  $color: map-get($variant, "color");
  $hover-background: map-get($variant, "hover-bg");
  $hover-border-color: map-get($variant, "hover-border-color");
  $hover-color: map-get($variant, "hover-color");
  $active-background: map-get($variant, "active-bg");
  $active-border-color: map-get($variant, "active-border-color");
  $active-color: map-get($variant, "active-color");
  $disabled-color: map-get($variant, "disabled-color");
  $shadow: map-get($variant, "shadow");

  .btn-outline-#{$state} {
    --#{$variable-prefix}btn-border-color: #{$color};
    --#{$variable-prefix}btn-color: #{$color};
    --#{$variable-prefix}btn-hover-bg: #{$hover-background};
    --#{$variable-prefix}btn-hover-border-color: #{$hover-border-color};
    --#{$variable-prefix}btn-hover-color: #{$hover-color};
    --#{$variable-prefix}btn-active-bg: #{$active-background};
    --#{$variable-prefix}btn-active-border-color: #{$active-border-color};
    --#{$variable-prefix}btn-active-color: #{$active-color};
    --#{$variable-prefix}btn-disabled-color: #{$disabled-color};
    --#{$variable-prefix}btn-shadow: #{$shadow};
  }
}
// scss-docs-end btn-outline-variant-mixin

// Button Ghost

@mixin button-ghost-variant($state, $variant) {
  $color: map-get($variant, "color");
  $hover-background: map-get($variant, "hover-bg");
  $hover-border-color: map-get($variant, "hover-border-color");
  $hover-color: map-get($variant, "hover-color");
  $active-background: map-get($variant, "active-bg");
  $active-border-color: map-get($variant, "active-border-color");
  $active-color: map-get($variant, "active-color");
  $disabled-color: map-get($variant, "disabled-color");
  $shadow: map-get($variant, "shadow");

  .btn-ghost-#{$state} {
    --#{$variable-prefix}btn-color: #{$color};
    --#{$variable-prefix}btn-hover-bg: #{$hover-background};
    --#{$variable-prefix}btn-hover-border-color: #{$hover-border-color};
    --#{$variable-prefix}btn-hover-color: #{$hover-color};
    --#{$variable-prefix}btn-active-bg: #{$active-background};
    --#{$variable-prefix}btn-active-border-color: #{$active-border-color};
    --#{$variable-prefix}btn-active-color: #{$active-color};
    --#{$variable-prefix}btn-disabled-color: #{$disabled-color};
    --#{$variable-prefix}btn-shadow: #{$shadow};
  }
}

// Button sizes
// scss-docs-start btn-size-mixin
@mixin button-size($padding-y, $padding-x, $font-size, $border-radius) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  // Manually declare to provide an override to the browser default
  @include border-radius($border-radius, 0);
}
// scss-docs-end btn-size-mixin
