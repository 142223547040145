.subheader {
  position: relative;
  display: flex;
  flex-wrap: wrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  min-height: $subheader-min-height;
  padding: $subheader-padding-y $subheader-padding-x;
  background: var(--#{$variable-prefix}subheader-bg, $subheader-bg);
  border-bottom: var(--#{$variable-prefix}subheader-border-width, $subheader-border-width) solid var(--#{$variable-prefix}subheader-border-color, $subheader-border-color);
}

.subheader-sticky {
  position: sticky;
  top: 0;
  z-index: $zindex-fixed - 1;
}

// Subheader nav
//
// Custom navbar navigation (doesn't require `.nav`, but does make use of `.nav-link`).

.subheader-nav {
  display: flex;
  flex-direction: row; // cannot use `inherit` to get the `.header`s value
  @include ltr-rtl("padding-left", 0);
  margin-bottom: 0;
  list-style: none;

  .nav-link {
    padding-right: $subheader-nav-link-padding-x;
    padding-left: $subheader-nav-link-padding-x;
    color: var(--#{$variable-prefix}subheader-color, $subheader-color);

    &:hover,
    &:focus {
      color: var(--#{$variable-prefix}subheader-hover-color, $subheader-hover-color);
    }

    &.disabled {
      color: var(--#{$variable-prefix}subheader-disabled-color, $subheader-disabled-color);
    }
  }

  .show > .nav-link,
  .nav-link.active {
    color: var(--#{$variable-prefix}subheader-active-color, $subheader-active-color);
  }

  .dropdown-menu {
    position: absolute;
  }
}

// Subheader text
//
//

.subheader-text {
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
  color: var(--#{$variable-prefix}subheader-color, $subheader-color);

  a {
    color: var(--#{$variable-prefix}subheader-active-color, $subheader-active-color);

    &:hover,
    &:focus {
      color: var(--#{$variable-prefix}subheader-active-color, $subheader-active-color);
    }
  }
}
